

















































































import Vue from 'vue'
import LoginForm from './OktaLoginForm.vue'
import LogoBrand from '@/components/shared/LogoBrand/index.vue'
import { routes } from '@/utils/routes'

export default Vue.extend({
  components: {
    LoginForm,
    LogoBrand
  },
  data() {
    return {
      routes
    }
  }
})
