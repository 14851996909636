import Vue from 'vue'
// import { IdToken, AccessToken } from 'Okta'
import param from 'jquery-param'
import { LoggedUser } from 'HealthAdvisorBackendApp'
import { RootState } from 'HealthAdvisorFrontendApp'
// import { initSignInWidget } from '@/utils/okta/initSignInWidget'
import {
  // checkOktaSession,
  makeActorObject,
  loginWithBackend,
  redirectUserAfterLogin
} from '@/utils/auth'

// This mixin in used to inject okta widget on DOM,
// check login state and handle user redirection to next step
// (if still onboarding)
export const checkLoggedStatusAndUpdateStore = Vue.extend({
  methods: {
    async checkLoggedStatusAndUpdateStore() {
      // const oktaSignIn = this.$store.state.oktaSiginWidget
      // const oktaSignIn = await initSignInWidget()

      // await this.$store.dispatch('setOktaSiginWidget', oktaSignIn)

      // check if user is logged in okta
      // const oktaSession = await checkOktaSession(oktaSignIn)
      // if (!oktaSession || !oktaSession.isLogged) {
      //   return
      // }

      // check if we have tokens stored in okta
      // const idToken: IdToken = await oktaSignIn.authClient.tokenManager.get(
      //   'idToken'
      // )
      // const accessToken: AccessToken = await oktaSignIn.authClient.tokenManager.get(
      //   'accessToken'
      // )
      // if (!idToken || !idToken.value || !accessToken || !accessToken.value) {
      //   return
      // }

      // check if user is logged in our backend
      const userMeUrlParams = param({
        token: 'none',
        aud: 'none',
        // token: accessToken.value,
        // aud: idToken.claims?.aud || '',
        needData: true
      })

      // in local development user/me/ endpoint will not work since we don't have wordpress cookie set on same domain
      // we can simulate same behaviour by calling login endpoint
      // this is only of page refresh
      const backendMeResponse =
        process.env.NODE_ENV === 'develop' ||
        process.env.NODE_ENV === 'development'
          ? await loginWithBackend(null, this.$axios)
          : await this.$axios.get(`/user/me/?${userMeUrlParams}`, {
              headers: {
                'X-Authorization': `Bearer none-1`
              }
            })

      // if we don't get user data, we have to wipe out data in vuex store
      // if we are in signup steps we need to keep cookie with user details that will me removed after completing step3 (payment)
      if (
        !backendMeResponse ||
        !backendMeResponse.data ||
        backendMeResponse.data.error
      ) {
        const keepUserCookie = Boolean(this.$route.path.includes('/signup'))
        this.$store.dispatch('flushActor', keepUserCookie)
        return
      }

      const loggedUserData: LoggedUser = backendMeResponse?.data.data
      const oktaUserId = loggedUserData?.ID || ''
      const storeActor = makeActorObject(
        loggedUserData,
        {
          authorizeUrl: 'string',
          claims: {
            amr: ['string'],
            at_hash: 'string',
            aud: 'string',
            auth_time: 34234234,
            email: 'string',
            exp: 3,
            iat: 2,
            idp: 'string',
            iss: 'string',
            jti: 'string',
            nonce: 'string',
            sub: 'string',
            ver: 1
          },
          clientId: 'string',
          expiresAt: 1,
          idToken: 'string',
          issuer: 'string',
          scopes: ['string'],
          value: 'string'
        },
        {
          accessToken: 'string',
          authorizeUrl: 'string',
          expiresAt: 1,
          scopes: ['string'],
          tokenType: 'string', // Bearer
          userinfoUrl: 'string',
          value: 'string'
        },
        oktaUserId
      )

      await this.$store.dispatch('setActorData', storeActor)

      if (!this.$data.preventUserRedirection) {
        redirectUserAfterLogin(loggedUserData.sign_up_step, this.$router)
      }
    }
  }
})

// This mixin watch for actor in vuex store and handle redirect if user is logged
// example we don't want logged user to use already completed onboarding steps
// or filling signup form
export const preventFromLoggedUser = Vue.extend({
  computed: {
    loggedUserFromStore() {
      return (this.$store.state as RootState).actor
    }
  },
  watch: {
    loggedUserFromStore: {
      handler() {
        this.checkIfLoggedFromStore()
      }
    }
  },
  methods: {
    checkIfLoggedFromStore() {
      const { actor } = this.$store.state as RootState
      if ((actor && actor.internalId) || (actor && actor.providerId)) {
        redirectUserAfterLogin(actor.signupStep || '', this.$router, true)
      }
    }
  }
})
