




































import Vue, { PropOptions } from 'vue'
// import { AccessToken, IdToken } from 'Okta'
import { LoggedUser } from 'HealthAdvisorBackendApp'
import { RootState } from 'HealthAdvisorFrontendApp'
import {
  loginWithBackend,
  checkOktaSession,
  logoutWithBackend,
  makeActorObject,
  redirectUserAfterLogin
} from '@/utils/auth'
import { checkLoggedStatusAndUpdateStore } from '@/utils/mixins/auth'

// import { initSignInWidget } from '@/utils/okta/initSignInWidget'
// import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

export default Vue.extend({
  name: 'OktaLoginForm',
  mixins: [checkLoggedStatusAndUpdateStore],
  props: {
    onLoginCallback: {
      type: Function,
      default: undefined
    } as PropOptions<() => void>
  },
  data() {
    return {
      isOktaLoaded: false,
      isLoading: false,
      apiErrorMessage: undefined as string | undefined,
      user: undefined as string | undefined
    }
  },
  beforeDestroy() {
    this.tryFormCleanUp()
  },
  async mounted() {
    // casting this as any because can't find a way to have TS infering available methods from mixins
    await (this as any).checkLoggedStatusAndUpdateStore()

    // we check if user is logged
    const storeState = this.$store.state as RootState
    const actor: any = {
      ...storeState.actor
    }

    // show login form
    if (!actor || !actor.providerId) {
      try {
        this.showLoginForm()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('catched showLoginForm', error)
      }
    }

    if (actor && actor.signupStep) {
      redirectUserAfterLogin(actor.signupStep, this.$router, true)
    }
  },
  methods: {
    logout() {
      // const okta = (this as any).$okta
      const { oktaSiginWidget } = this.$store.state as RootState
      logoutWithBackend(oktaSiginWidget, this.$axios)
      this.$data.user = undefined
      this.$store.dispatch('flushActor')
      this.showLoginForm()
    },
    // oktaLogout() {
    //   // soft logout
    //   const { oktaSiginWidget } = this.$store.state as RootState

    //   this.$data.isLoading = true
    //   this.$data.user = undefined
    //   oktaSiginWidget.authClient.revokeAccessToken().then(() => {
    //     oktaSiginWidget.authClient.closeSession().then(() => {
    //       this.$store.dispatch('flushActor')
    //       this.$data.isLoading = false
    //       setTimeout(() => {
    //         this.showLoginForm()
    //       }, 500)
    //     })
    //   })
    // },
    tryFormCleanUp() {
      const { oktaSiginWidget } = this.$store.state as RootState

      if (!oktaSiginWidget || !oktaSiginWidget.remove) {
        return
      }
      try {
        oktaSiginWidget.remove()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('catched tryFormCleanUp', error)
      }
    },
    showLoginForm() {
      // Important:
      // using oktaSiginWidget as new reference,
      // since we have to mutate tokenManager with its .add() method
      // and it can be done directly on vuex observable
      const oktaSiginWidget = {
        ...(this.$store.state as RootState).oktaSiginWidget
      }
      this.isOktaLoaded = true

      if (!oktaSiginWidget || !oktaSiginWidget.renderEl) {
        return
      }

      this.tryFormCleanUp()

      oktaSiginWidget.renderEl(
        {
          el: '#okta-widget-container'
        },
        async (responseWithTokens: any) => {
          const idToken: IdToken = responseWithTokens?.tokens?.idToken
          const accessToken: AccessToken =
            responseWithTokens?.tokens?.accessToken

          oktaSiginWidget.authClient.tokenManager.add('idToken', idToken)
          oktaSiginWidget.authClient.tokenManager.add(
            'accessToken',
            accessToken
          )
          localStorage.setItem('oktaState', responseWithTokens.state)

          const signinApiReponse = await loginWithBackend(
            oktaSiginWidget,
            this.$axios
          )

          const oktaSession = await checkOktaSession(oktaSiginWidget)

          if (
            !signinApiReponse ||
            !signinApiReponse.data ||
            !signinApiReponse.data.data ||
            !signinApiReponse.data.data.ID ||
            !oktaSession ||
            !oktaSession.isLogged
          ) {
            // error login with backend
            // this.oktaLogout()
            this.apiErrorMessage = `Login error. Cannot verified user with our server. Please refresh and retry.`

            return
          }

          const loggedUserData: LoggedUser = signinApiReponse?.data.data
          const oktaUserId = oktaSession.oktaUser?.id || ''
          const storeActor = makeActorObject(
            loggedUserData,
            idToken,
            accessToken,
            oktaUserId
          )

          // user is logged in, let's dispatch updated user data to store
          this.$store.dispatch('setActorData', storeActor).then(() => {
            // hide form and take user to somewhere
            if (oktaSiginWidget && oktaSiginWidget.remove) {
              oktaSiginWidget.remove()
            }
            if (this.$props.onLoginCallback) {
              this.$props.onLoginCallback()
            } else {
              // take user to last step to complete in case they still have to complete onboarding
              redirectUserAfterLogin(
                loggedUserData.sign_up_step,
                this.$router,
                true
              )
            }
          })
        }
      )
    }
  }
})
