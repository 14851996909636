




import Vue from 'vue'
import { PageData } from 'HealthAdvisorBackendApp'
import { API_PAGE_LOGIN } from '@/utils/settings'
import LoginComponent from '@/components/login/index.vue'
import { apiFetcher } from '@/utils/api/fetcher'

export default Vue.extend({
  name: 'LoginPage',
  components: {
    LoginComponent
  },
  async asyncData({ $axios }) {
    const fetchedData = await apiFetcher<PageData>({
      $axios,
      apiUrl: `/page/${API_PAGE_LOGIN}/`
    })
    const { head, schemaJsonLD } = fetchedData
    return {
      head,
      schemaJsonLD
    }
  },
  jsonld() {
    return this.$data.schemaJsonLD || null
  },
  head() {
    return this.$data.head
      ? {
          ...this.$data.head
        }
      : {}
  }
})
